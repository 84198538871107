<template>
    <div>
        <jy-query ref="form" :model="form">
            <jy-query-item label="线路:" prop="routeId">
                <!-- <div @click="xlselectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
                <el-select v-model="form.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="车牌号:">
                <el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item label="统计日期:" prop="time" :span="1.5">
                <el-date-picker v-model="form.time" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :unlink-panels="true"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('reset')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="operateDate" label="营运日期"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
            <jy-table-column prop="upTripNum" label="上行班次"></jy-table-column>
            <jy-table-column prop="downTripNum" label="下行班次"></jy-table-column>
            <jy-table-column prop="upMileage" label="上行里程"></jy-table-column>
            <jy-table-column prop="downMileage" label="下行里程"></jy-table-column>
            <jy-table-column prop="waybillMileage" label="路单里程"></jy-table-column>
            <jy-table-column prop="assistMileage" label="辅助里程"></jy-table-column>
            <jy-table-column prop="totalMileage" label="总里程"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <xlinstitutions-tree ref="xlinstitutions" @addInstitutions="xladdInstitutions" url="/waybillOperate/getTree" title="选择线路" :defaultProps="props"></xlinstitutions-tree>
    </div>
</template>
<script>
import xlinstitutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            props: {
                children: 'children',
                label: 'text',
                value: 'id'
            },

            form: {
                routeName: '',
                routeId: '',
                vehicleNo: '',
                time: [],
                beginDate: '',
                endDate: ''
            },
            dataList: [],
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            routeOptions:[],

            btnRoute:'/admin/statisticalAnalysis/tripMileage/tripMileageCarEveryDay',
        }
    },
    created() {
        this.$nextTick(() => {
            this.$message({
                type: 'warning',
                message: '请输入完整统计日期区间范围及车牌号',
            })
        })
    },
    activated() {
        this.getRouteList()
    },
    mixins:[btnMixins],
    components: {
        xlinstitutionsTree,
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        getList() {
            let option = {
                ...this.form
            }
            if (option.time) {
                option.beginDate = option.time[0]
                option.endDate = option.time[1]
            } else {
                option.beginDate = ''
                option.endDate = ''
            }
            let url = '/stattripmileagedaily/queryVehicleDailyTotal'
            this.$http.post(url, option).then(res => {
                // if (res.retain == '1') {
                //     this.dataList = []
                //     this.$message({
                //         message: res.error,
                //         type: 'warning',
                //     })
                // } else {
                    this.dataList = res.detail.list
                    this.total = res.detail.total
                // }
            })
        },
        // 查询表格
        oncheck() {
            if(!this.form.time || this.form.time.length != 2 || !this.form.vehicleNo){
                this.$message({
                    type: 'warning',
                    message: '请输入完整统计日期区间范围及车牌号',
                })
                return false
            }
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form.routeId = ''
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getList()
        },
        // 选择线路树
        xlselectInstitutions() {
            this.$refs.xlinstitutions.init()
        },
        // 上级线路机构查询
        xladdInstitutions(data) {
            // console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        }
    }
}

</script>
